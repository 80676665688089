.projects-container {
  position: relative;
  overflow: hidden;
}

.projects-heading {
  position: absolute;
  top: 3%;
  left: 16%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  color: #fff;
  padding: 10px;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.project-stars {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  transform: rotateY(90deg);
}

/* NEPTUNE */
.project-neptune {
  position: absolute;
  top: 8%;
  left: 20%;
  transform: translateX(-50%);
  border-radius: 100%;
  z-index: 2;
}

.project-line-1-container {
  position: absolute;
  top: 13%;
  left: 35%;
  width: 38%;
  z-index: 2;
}
.project-line-1 {
  position: absolute;
  top: 0;
  left: -20%;
  width: 110%;
  height: 1px;
  background-color: #fff;
}

.project-text-1 {
  position: absolute;
  top: -24px;
  left: 73%;
  color: #6897ff;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 22px;
}

.project-description-1 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-align: justify;
  line-height: 1.5;
  padding-top: 5%;
  padding-left: 12%;
}

.oval-shapes-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  padding-left: 13%;
}

.oval-shape-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: rgba(104, 151, 255, 0.67);
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  border-radius: 50px;
  margin: 5px;
}

.oval-shape-1:hover {
  background-color: #6897ff;
  transform: scale(1.1) perspective(800px) rotateY(10deg);
}

/* JUPITER */
.project-jupiter {
  position: absolute;
  top: 28%;
  left: 80%;
  transform: translateX(-50%);
  border-radius: 100%;
  z-index: 2;
  max-width: 920px;
  max-height: 920px;
}

.project-line-2-container {
  position: absolute;
  top: 35%;
  left: 32%;
  width: 34%;
  z-index: 2;
}

.project-line-2 {
  position: absolute;
  top: 0;
  left: 10%;
  width: 120%;
  height: 1px;
  background-color: #fff;
}

.project-text-2 {
  position: absolute;
  top: -24px;
  left: 10%;
  color: #b68e68;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 22px;
}

.project-description-2 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-align: justify;
  line-height: 1.5;
  padding-top: 5%;
  padding-left: 10%;
}

.oval-shapes-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-left: 14%;
}

.oval-shape-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: rgba(182, 142, 104, 0.8);
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  border-radius: 50px;
  margin: 5px;
}

.oval-shape-2:hover {
  background-color: #b68e68;
  transform: scale(1.1) perspective(800px) rotateY(10deg);
}

/* MERCURY */
.project-mercury {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateX(-50%);
  border-radius: 100%;
  z-index: 2;
  max-width: 500px;
  max-height: 500px;
}

.project-line-3-container {
  position: absolute;
  top: 55%;
  left: 35%;
  width: 38%;
  z-index: 2;
}

.project-line-3 {
  position: absolute;
  top: 0;
  left: -12%;
  width: 110%;
  height: 1px;
  background-color: #fff;
}

.project-text-3 {
  position: absolute;
  top: -24px;
  left: 59%;
  color: #868686;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 22px;
}

.project-description-3 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-align: justify;
  line-height: 1.5;
  padding-top: 5%;
  padding-left: 12%;
}

.oval-shapes-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-left: 14%;
}

.oval-shape-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: rgba(134, 134, 134, 0.8);
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  border-radius: 50px;
  margin: 5px;
}

.oval-shape-3:hover {
  background-color: #868686;
  transform: scale(1.1) perspective(800px) rotateY(10deg);
}

.project-line-other-container {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.project-other-title {
  font-size: 24px;
  color: #fff;
  padding: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
}

.square-shapes {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.square-shape {
  width: 200px;
  height: 200px;
  background-color: #ffffff;
  margin: 0 10px;
  border-radius: 20%;
  position: relative;
  transition: transform 0.5s;
  cursor: pointer;
}

.square-front {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20%;
  font-size: 19px;
  text-align: center;
  padding-top: 40%;
}

.square-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20%;
  font-size: 16px;
  visibility: hidden;
  text-align: center;
  padding-top: 20%;
}

.flip .square-front {
  visibility: hidden;
}

.flip .square-back {
  visibility: visible;
}




@media only screen and (max-width: 1200px) {
  .project-text-3 {
    position: absolute;
    top: -24px;
    left: 40%;
    color: #868686;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 22px;
  }
}

@media only screen and (max-width: 1000px) {
  .projects-heading {
    top: 10%;
    left: 50%;
    font-size: 20px;
    color: #fff;
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }

  .project-line-1,
  .project-line-2,
  .project-line-3 {
    display: none;
  }

  .project-neptune,
  .project-jupiter,
  .project-mercury {
    display: none;
  }

  .project-line-1-container,
  .project-line-2-container,
  .project-line-3-container {
    width: 100%;
    left: 0;
    padding-top: 50px;
  }

  .project-text-1 {
    position: absolute;
    top: 30px;
    left: 5%;
    color: #6897ff;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
  }

  .project-text-2 {
    position: absolute;
    top: 30px;
    left: 5%;
    color: #b68e68;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
  }

  .project-text-3 {
    position: absolute;
    top: 30px;
    left: 5%;
    color: #868686;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
  }

  .project-description-1,
  .project-description-2,
  .project-description-3 {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 14px;
    text-align: justify;
    line-height: 1.5;
    padding-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
  }

  .oval-shapes-1,
  .oval-shapes-2,
  .oval-shapes-3 {
    display: none;
  }

  .project-line-other-container {
    padding-top: 25%;
    left: 50%;
  }

  .project-other-title {
    font-size: 18px;
    color: #fff;
    padding: 10px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
  }

  .square-shapes {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .square-shape {
    width: 100px;
    height: 100px;
    background-color: #ffffff;
    opacity: 0.7;
    margin: 0 10px;
    border-radius: 20%;
    margin-bottom: 20%;
  }
}
