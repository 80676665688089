#about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15%;
}

body {
  background-color: black;
}

.about-main {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
}

.about-astronaut {
  margin-top: 100px;
  margin-right: 55%;
}

.about-title {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 40px;
  color: white;
  padding-bottom: 5%;
}

.about-text-1 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  padding-top: 2%;
  max-width: 500px;
  margin-left: auto;
  display: inline-block;
}

.about-text-2,
.about-text-3,
.about-text-4 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  padding-top: 4%;
  max-width: 500px;
  margin-left: auto;
}

.about-bold {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #73c3bb;
}

.about-animation {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #84e2d8;
  text-align: center;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) 3s both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) 3s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 700px) {
  #about {
    align-items: center;
  }

  .about-main {
    width: 100%;
  }
  .about-title {
    font-size: 18px; /* Adjust the font size as desired */
    padding-bottom: 10%; /* Adjust the spacing as desired */
    padding-top: 40%;
  }


  .about-image {
    max-width: 200px;
    max-height: 200px;
    margin-left: 0px;
    margin-bottom: 20px;
    margin-top: 40px;
    align-self: center;
  }

  .about-text-1 {
    font-size: 12px;
  }
  .about-text-2,
  .about-text-3,
  .about-text-4 {
    font-size: 12px;
  }
  .about-astronaut {
    display: none;
  }
  .about-bold {
    font-weight: 500;
    font-size: 12px;
  }
}
