.landing-main {
  position: relative;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#landing {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 72%;
  padding-top: 24.8%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease-in-out;
}

#landing.show-animation {
  opacity: 1;
}

.landing-title {
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 200;
  font-size: 35px;
}

.landing-name {
  color: #fcf3d7;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 40px;
}

.landing-body {
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 200;
  font-size: 20px;
  padding-top: 2%;
}

.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  #landing {
    min-height: unset;
    padding-left: unset;
    align-items: flex-end;
  }

  .landing-title {
    font-size: 26px;
  }

  .landing-name{
    font-size: 26px;

  }
  .landing-body {
    font-size: 12px;
  }
}
