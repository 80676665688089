.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.navbar-logo {
  max-width: 50px;
  max-height: 50px;
  padding-top: 0.8%;
  padding-left: 0.5%;
}

.navbar-items {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  letter-spacing: 0.19em;
  font-weight: 300;
}

.navbar-item {
  opacity: 0;
  animation: fadeDown 0.5s ease-in-out forwards;
}

.navbar-item:nth-child(1) {
  animation-delay: 0.2s;
}

.navbar-item:nth-child(2) {
  animation-delay: 0.4s;
}

.navbar-item:nth-child(3) {
  animation-delay: 0.6s;
}

.navbar-item:nth-child(4) {
  animation-delay: 0.8s;
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rs-sidenav-subtle {
  background-color: transparent !important;
}

a {
  text-decoration: none !important;
  color: white !important;
}

a:hover {
  color: #fcf3d7 !important;
}
